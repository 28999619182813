<template>
  <main
    class="wrap-bg"
    :style="{ backgroundImage: `url(${host}h5-active/14/bg.png)` }"
  >
    <div class="recharge-info">
      <h3>充值{{ config.recharge_money / 100 || "" }}元</h3>
      <div v-if="config.give_type === 1 || config.give_type === '1'">
        得<span>{{ config.recharge_money }}</span
        >书币+<span>{{ config.give_ticket }}</span
        >阅读劵
      </div>
      <div v-else>全站小说免费读({{ config.give_vip_days || 0 }}天)</div>
    </div>
    <img
      class="recharge-btn"
      :src="host + 'h5-active/14/btn.png'"
      @click="createOrder"
    />
    <div class="tips">
      <p>活动规则:</p>
      <p v-if="config.recharge_count != 9999">
        每一期活动单一用户仅能充值{{ config.recharge_count }}次
      </p>
      <p>虚拟商品购买后不可退换</p>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
    },
    host: String,
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../style.less";

.recharge-info {
  top: 310px;
  h3 {
    font-size: 28px;
    font-weight: bold;
    color: #b24606;
    margin-bottom: 20px;
    text-align: center;
  }
  div {
    color: #dd3c31;
    white-space: nowrap;
    text-align: center;
    font-size: 20px;
  }
}
.recharge-btn {
  position: absolute;
  top: 400px;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  z-index: 20;
}
.tips {
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #fff;
  z-index: 1;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
}
</style>
