<template>
  <main
    class="wrap-bg"
    :style="{ backgroundImage: `url(${host}h5-active/32/bg.png)` }"
  >
    <section
      class="content"
      :style="{ backgroundImage: `url(${host}h5-active/32/center.png)` }"
    >
      <div class="recharge-info">
        <h3>充值{{ config.recharge_money / 100 || "" }}元</h3>
        <div v-if="config.give_type === 1 || config.give_type === '1'">
          得<span>{{ config.recharge_money }}</span
          >书币+<span>{{ config.give_ticket }}</span
          >阅读劵
        </div>
        <div v-else>全站小说免费读({{ config.give_vip_days || 0 }}天)</div>
      </div>
      <img
        class="recharge-btn"
        :src="host + 'h5-active/32/btn.png'"
        @click="createOrder"
      />
    </section>
    <div class="tips">
      <p>-活动规则-</p>
      <p v-if="config.recharge_count != 9999">
        每一期活动单一用户仅能充值{{ config.recharge_count }}次
      </p>
      <p>虚拟商品购买后不可退换</p>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
    },
    host: String,
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap-bg {
  width: 100%;
  height: 100vh;
  min-height: 667px;
  max-height: 820px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 85vw;
  max-width: 430px;
  height: 65vw;
  background-size: cover;
  background-position: center;
}
.recharge-info {
  padding: 8vh 0 6vh;
  color: #e38b2e;
  font-size: 22px;
  font-weight: bold;
  h3 {
    font-size: 30px;
    color: #e38b2e;
    margin-bottom: 3vh;
    text-align: center;
    text-shadow: 0px 2px 0px #fffbf2;
  }
  div {
    white-space: nowrap;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
}
.recharge-btn {
  display: block;
  width: 28vh;
  height: auto;
  margin: 0 auto;
}
.tips {
  font-weight: bold;
  position: absolute;
  bottom: 3vh;
  left: 0;
  width: 100%;
  font-size: 16px;
  color: #fff;
  z-index: 1;
  line-height: 26px;
  font-weight: 400;
  p {
    text-align: center;
  }
}
</style>
