<template>
  <!-- Theme2 是vip会员天, 和其他的差别较大  -->
  <component
    v-if="rechargeInfo.theme_id"
    v-bind:is="'Theme' + rechargeInfo.theme_id"
    :host="URL"
    :config="rechargeInfo"
    @createOrder="createOrder"
  />
  <van-popup v-model:show="show" position="bottom" :style="{ height: '160px' }">
    <div class="pay-box">
      <van-button
        icon="wechat"
        size="large"
        color="#75D6DC"
        @click="toPay(1)"
      >微信支付</van-button>
      <van-button
        icon="alipay"
        type="primary"
        size="large"
        @click="toPay(2)"
      >支付宝支付</van-button>
    </div>
  </van-popup>
</template>

<script>
import { Popup, Button, Toast } from 'vant'
import Theme1 from './styleTheme/theme1.vue'
import Theme2 from './styleTheme/theme2.vue'
import Theme3 from './styleTheme/theme3.vue'
import Theme4 from './styleTheme/theme4.vue'
import Theme5 from './styleTheme/theme5.vue'
import Theme6 from './styleTheme/theme6.vue'
import Theme7 from './styleTheme/theme7.vue'
import Theme8 from './styleTheme/theme8.vue'
import Theme9 from './styleTheme/theme9.vue'
import Theme10 from './styleTheme/theme10.vue'
import Theme11 from './styleTheme/theme11.vue'
import Theme12 from './styleTheme/theme12.vue'
import Theme13 from './styleTheme/theme13.vue'
import Theme14 from './styleTheme/theme14.vue'
import Theme15 from './styleTheme/theme15.vue'
import Theme16 from './styleTheme/theme16.vue'
import Theme17 from './styleTheme/theme17.vue'
import Theme18 from './styleTheme/theme18.vue'
import Theme19 from './styleTheme/theme19.vue'
import Theme20 from './styleTheme/theme20.vue'
import Theme21 from './styleTheme/theme21.vue'
import Theme22 from './styleTheme/theme22.vue'
import Theme23 from './styleTheme/theme23.vue'
import Theme24 from './styleTheme/theme24.vue'
import Theme25 from './styleTheme/theme25.vue'
import Theme26 from './styleTheme/theme26.vue'
import Theme27 from './styleTheme/theme27.vue'
import Theme28 from './styleTheme/theme28.vue'
import Theme29 from './styleTheme/theme29.vue'
import Theme30 from './styleTheme/theme30.vue'
import Theme31 from './styleTheme/theme31.vue'
import Theme32 from './styleTheme/theme32.vue'
import Theme33 from './styleTheme/theme33.vue'
import Theme34 from './styleTheme/theme34.vue'
import Theme35 from './styleTheme/theme35.vue'
import Theme36 from './styleTheme/theme36.vue'
import Theme37 from './styleTheme/theme37.vue'
import Theme38 from './styleTheme/theme38.vue'
import Theme39 from './styleTheme/theme39.vue'
import Theme40 from './styleTheme/theme40.vue'
import Theme41 from './styleTheme/theme41.vue'
import Theme42 from './styleTheme/theme42.vue'
import Theme100 from './styleTheme/theme100.vue'
import Theme101 from './styleTheme/theme101.vue'
import Theme102 from './styleTheme/theme102.vue'
import Theme103 from './styleTheme/theme103.vue'
import Theme105 from './styleTheme/theme105.vue'
import Theme150 from './styleTheme/theme150.vue'
import Theme152 from './styleTheme/theme152.vue'
import Theme153 from './styleTheme/theme153.vue'


import { getActivityDetail, orderCreate, reportPvUv } from '../../api/active'
import { URL } from './config'

export default {
  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
    Theme1,
    Theme2,
    Theme3,
    Theme4,
    Theme5,
    Theme6,
    Theme7,
    Theme8,
    Theme9,
    Theme10,
    Theme11,
    Theme12,
    Theme13,
    Theme14,
    Theme15,
    Theme16,
    Theme17,
    Theme18,
    Theme19,
    Theme20,
    Theme21,
    Theme22,
    Theme23,
    Theme24,
    Theme25,
    Theme26,
    Theme27,
    Theme28,
    Theme29,
    Theme30,
    Theme31,
    Theme32,
    Theme33,
    Theme34,
    Theme35,
    Theme36,
    Theme37,
    Theme38,
    Theme39,
    Theme40,
    Theme41,
    Theme42,
    Theme100,
    Theme101,
    Theme102,
    Theme103,
    Theme105,
    Theme150,
    Theme152,
    Theme153
  },
  created() {
    const {view, ...params} = this.$route.query
    if(view){
      this.rechargeInfo = params
      document.title = params.title
    }else{
      getActivityDetail({activity_id: this.activeId}).then(res => {
        this.rechargeInfo = res.data
        document.title = res.data.title
      })
    }
    this.reportActivityPvUv()
    try {
      // 注册接受快应用信息
      system.onmessage = (data) => {
        const {code, message} = JSON.parse(data)
        if (code === 200) {
          Toast.success(message)
          system.postMessage("{'action':'close'}")
        } else {
          Toast.fail(message)
        }
      }
    } catch(_err) {}

  },
  data() {
    return {
      activeId: this.$route.params.activeId,
      rechargeInfo: {
        rechargeCount: 1,
        theme_id: null,
      },
      URL,
      show: false,
      ['IRIS-RECOGNITION']: this.$route.query['token'],
      ['app-appid']: this.$route.query['app_id'],
      ['os-deviceid']: this.$route.query['device_id'],
    }
  },
  methods: {
    reportActivityPvUv() {
      let yy = new Date().getFullYear();
      let mm = (new Date().getMonth()+1).toString().padStart(2,'0')
      let dd = new Date().getDate().toString().padStart(2,'0')
      let hh = new Date().getHours().toString().padStart(2,'0')
      let mf = new Date().getMinutes().toString().padStart(2,'0')
      let ss = new Date().getSeconds().toString().padStart(2,'0')
      reportPvUv(
        {
          data:[{
            "page_code":"ACTIVITY_DETAIL",
            "action_type":"ACTIVITY",
            'action_value':'ACTIVITY',
            'action_time': yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss,
             content: {activity_id: this.activeId},
            'from_id': this.activeId
          }]
        },
        {
          'IRIS-RECOGNITION': this['IRIS-RECOGNITION'],
          'app-appid': this['app-appid'],
          'os-deviceid': this['os-deviceid'],
          'app-type': 'quickapp'
        }
      )
    },
    createOrder() {
      // 如果是预览的 就不让其唤起支付
      if(this.$route.query.view) return
      this.show = true
    },
    // type 1为微信, 2为支付宝
    toPay(type) {
      const return_url = process.env.NODE_ENV === 'dev' ? 'http://localhost:8080/aliPay.html' : 'http://zgh5.tjaiyou.net/aliPay.html'
      const message = {
        type,
        payment_type: 2,
        recharge_id: this.rechargeInfo.id,
        return_url,
        from_type: 'ACTIVITY',
        from_code: this.activeId
      }
      try {
        system.postMessage(JSON.stringify(message))
      } catch(_err) {}
    },
    toWechatPayment() {
      const baseUrl = 'https://api.zg.haidubooks.com/order.html?'
      const timestamp = new Date().getTime()
      window.location.href = baseUrl + `recharge_id=${this.rechargeInfo.id}&type=2&token=${this['IRIS-RECOGNITION']}&app_id=${this['app-appid']}&device_id=${this['os-deviceid']}&from_type=ACTIVITY&from_code=${this.activeId}&t=${timestamp}`
    },
    toAliPayment() {
      const return_url = process.env.NODE_ENV === 'dev' ? 'http://localhost:8080/aliPay.html' : 'http://h5.zg.tuantuanbook.net/aliPay.html'
      orderCreate(
        {
          payment_type: 2,
          type: 2,
          recharge_id: this.rechargeInfo.id,
          return_url,
          from_type: 'ACTIVITY',
          from_code: this.activeId
        },
        {
          'IRIS-RECOGNITION': this['IRIS-RECOGNITION'],
          'app-appid': this['app-appid'],
          'os-deviceid': this['os-deviceid'],
          'app-type': 'h5-pay'
        }
      ).then(res => {
        const { order_info } = res.data || {}
        const div = document.createElement("div")
        if (order_info) {
          div.innerHTML = order_info
          document.body.appendChild(div)
          const formNode = document.querySelector('form')
          formNode.submit()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pay-box {
  padding: 10px 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
