<template>
  <main
    class="wrap-bg"
    :style="{ backgroundImage: `url(${host}h5-active/38/bg.png)` }"
  >
    <div class="recharge-info">
      <h3>充值{{ config.recharge_money / 100 || "" }}元</h3>
      <div v-if="config.give_type === 1 || config.give_type === '1'">
        得<span>{{ config.recharge_money }}</span
        >书币+<span>{{ config.give_ticket }}</span
        >阅读劵
      </div>
      <div v-else>全站小说免费读({{ config.give_vip_days || 0 }}天)</div>
    </div>
    <img
      class="recharge-btn"
      :src="host + 'h5-active/38/btn.png'"
      @click="createOrder"
    />
    <div class="tips">
      <p>-活动规则-</p>
      <p v-if="config.recharge_count != 9999">
        每一期活动单一用户仅能充值{{ config.recharge_count }}次
      </p>
      <p>虚拟商品购买后不可退换</p>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
    },
    host: String,
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../style.less";
.wrap-bg {
  width: 100%;
  height: 85vh;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.recharge-info {
  top: 44vh;
  h3 {
    font-size: 35px;
    color: #0f6e5e;
    margin-bottom: 5vw;
    text-align: center;
    text-shadow: 0px 2px 0px #fffbf2;
  }
  div {
    color: #39c2a3;
    white-space: nowrap;
    text-align: center;
    font-size: 18px;
  }
}
.recharge-btn {
  position: absolute;
  top: 60vh;
  left: 50%;
  width: 32vh;
  transform: translateX(-50%);
  z-index: 20;
}
.tips {
  font-weight: bold;
  position: absolute;
  bottom: 19px;
  left: 0;
  width: 100%;
  font-size: 16px;
  color: #fff;
  z-index: 1;
  line-height: 26px;
  font-weight: 400;
  p {
    text-align: center;
  }
}
</style>
