<template>
  <main class="wrap-bg" :style="{backgroundImage: `url(${host}h5-active/2/bg.png)`}">
    <div class="recharge-info"> 
      充值{{config.recharge_money/100}}元, 送{{config.give_vip_days || 0}}天会员
    </div>
    <img class="recharge-btn" :src="host+'h5-active/2/btn.png'" @click="createOrder">
    <div class="tips">
      <p>VIP属于虚拟商品，一经购买不得退换</p>
      <p>青少年请在家长陪同下充值</p>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    config: {
      type: Object
    },
    host: String
  },
  methods: {
    createOrder() {
      this.$emit('createOrder')
    }
  }
}
</script>

<style lang="less" scoped>
.wrap-bg {
  width: 100%;
  height: 100vh;
  min-height: 667px;
  max-height: 820px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.recharge-info {
  width: 75%;
  height: 42px;
  border: 1px solid #ae936b;
  box-sizing: border-box;
  margin: 0 auto;
  color: #ae936b;
  line-height: 42px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 65%;
}
.recharge-btn{
  width: 75%;
  position: absolute;
  top: 74%;
  left: 50%;
  transform: translateX(-50%);
}
.tips {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #696c76;
  z-index: 1;
  line-height: 26px;
  font-weight: bold;
  p{
    text-align: center;
  }
}
</style>